<template>
  <div class="locale-changer mx-5 my-2">
    <span>{{ $t("menu.select-language")}}</span>
    <b-form-select
      id="select_language"
      v-model="$i18n.locale"
      :options="langs"
      size="sm"
      @change="changeLanguage()"
    ></b-form-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormSelect } from 'bootstrap-vue';

export default {
  name: 'SelectLanguage',
  components: {
    BFormSelect,
  },
  data() {
    return {
      langs: [
        { value: 'he', text: 'עברית' },
        { value: 'en', text: 'English' },
        // { value: 'ru', text: 'Russian' },
        { value: 'ar', text: 'عربي' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      categoryID: 'GET_CURRENT_CATEGORY',
    }),
    queryLang() {
      const qLang = this.$route?.query?.langCode?.toUpperCase() || '';
      // eslint-disable-next-line no-nested-ternary
      return qLang === 'HEB' ? 'he' : qLang === 'ENG' ? 'en' : qLang === 'ARA' ? 'ar' : '';
    },
  },
  mounted() {
    this.$i18n.locale = this.queryLang || 'he';
    this.changeLanguage();
  },
  methods: {
    async changeLanguage() {
      if (this.categoryID && this.categoryID.categoryId === 'Hotel_Only') {
        await this.$store.dispatch('FETCH_LIST_COUNTRIES');
      }
      this.$store.dispatch('UPDATE_LANGUAGE', this.$i18n.locale);
      window.sessionStorage.setItem('language', this.$i18n.locale);
    },
  },
};
</script>
